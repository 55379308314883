$breakpoint-desktop-extra: 1440px;
$breakpoint-desktop: 1200px;
$breakpoint-tablet-extra: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile-extra: 640px;

$font-family: 'Montserrat', sans-serif;
$font-family-ruble: 'ALSRubl', sans-serif;

$color-font: #000; 
$color-font-gray: #919699;
$color-black: #000000; 
$color-black-20p: rgba(0, 0, 0, 0.2); 
$color-white: #ffffff; 
$color-white-10p: rgba(255, 255, 255, 0.1); 
$color-white-30p: rgba(255, 255, 255, 0.3); 
$color-white-50p: rgba(255, 255, 255, 0.5); 
$color-white-60p: rgba(255, 255, 255, 0.6); 
$color-white-70p: rgba(255, 255, 255, 0.7); 
$color-gray: #bbbbbb;
$color-gray-40p: rgba(187, 187, 187, 0.4);
$color-orange: #ff8a00;
$color-orange2: #ff5e00;
$color-red: #a02727;
