.promo {
    z-index: 1;
    position: relative;
    height: 100vh;

    &__sandwich {
        @media (min-width: $breakpoint-desktop) {
            display: none;
        }
    }

    &__header {
        padding-top: 3rem;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;

        &-inner {
            position: relative;
            padding-left: 2rem;
            padding-right: 2rem;
            max-width: 124rem;
            margin: auto;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }
    &__btn {
        padding: 0.7rem 2rem;
        margin-left: auto;
        display: none;
        border-radius: 100rem;
        background: rgba(103, 103, 103, 0.39);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $color-gray;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: $color-orange;
        }

        @media (min-width: $breakpoint-desktop) {
            display: block;
        }
    }
    &__btn-orange {
        padding: 1rem 2rem;
        display: inline-block;
        background-color: $color-orange2;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-size: 1.7rem;
        width: calc(100vw - 4rem);
        color: $color-white;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: $color-orange;
        }

        @media (min-width: $breakpoint-mobile-extra) {
            margin-top: -2rem;
            width: auto;
            border-radius: 100rem;
        }
        @media (min-width: $breakpoint-desktop) {
            display: none;
        }
    }
    &__logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        @media (min-width: $breakpoint-desktop) {
            margin-left: 4rem;
            position: static;
            transform: none;
        }

        img {
            max-width: 100%;
            width: 16rem;
            
            @media (min-width: $breakpoint-tablet) {
                width: auto;
            }
        }
    }
    &__pic {
        img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            object-fit: cover;
        }

        //&-big {
        //    display: none;
        //
        //    @media (min-width: $breakpoint-mobile-extra) {
        //        display: block;
        //    }
        //}
        //
        //&-small {
        //
        //    @media (min-width: $breakpoint-mobile-extra) {
        //        display: none;
        //    }
        //}



        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 9rem;
            background: url('../img/promo-mask.svg') 50% 100% repeat-x;
            z-index: 1;
            pointer-events: none;
        }
    }
    &__content {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 175%;

        @media (min-width: $breakpoint-tablet) {
            top: 60%;
        }
        @media (min-width: $breakpoint-desktop) {
            width: 108%;
        }

        p {
            text-align: center;

            &:first-child {
                margin-bottom: -2.5em;
                padding-left: 0.9em;
                color: $color-white-60p;
                letter-spacing: 0.9em;
                text-transform: uppercase;
                font-size: 1rem;

                @media (min-width: $breakpoint-mobile-extra) {
                    font-size: 1.7rem;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 2.1rem;
                }
                @media (min-width: $breakpoint-desktop) {
                    font-size: 2.6rem;
                }
            }
            &:last-child {
                @media (min-width: $breakpoint-tablet) {
                    margin-top: -4rem;
                }
                @media (min-width: $breakpoint-desktop) {
                    display: none;
                }
            }
        }

        img {
            width: 100%;
        }
    }
    &__social {
        padding: 2rem;
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-color: $color-white;

        @media (min-width: $breakpoint-tablet) {
            display: block;
        }

        a {
            display: block;
            padding-bottom: 2rem;
            position: relative;

            &::before {
                content: "*";
                position: absolute;
                color: #6d6d6d;
                left: 2rem;
                top: -0.5rem;
            }

            &:last-child {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
            }

            img {
                transition: opacity 0.2s ease-in-out;
            }
            &:hover img {
                opacity: 0.5;
            }

            &:hover {
                span {
                    display: inline-block;
                }
            }
        }
    }
    &__seemore {
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform-origin: center center;
        transform: rotate(-90deg) translateY(-225%);
        color: $color-orange;
        cursor: pointer;

        @media (min-width: $breakpoint-desktop) {
            display: block;
        }

        img {
            margin-right: 1rem;
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            img {
                transform: translateX(-2rem);
            }
        }
    }

    &--header {
        height: auto;
    }
    &--header  &__header {
        position: static;
    }
    &--header &__btn {
        border: 1px solid $color-gray;
        background: none;
        color: $color-black;

        &:hover {
            color: $color-orange;
        }
    }
}
