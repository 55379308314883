.news {
    padding: 7rem 0;
    max-width: 100rem;
    margin: auto;

    @media (min-width: $breakpoint-mobile-extra) {
        padding: 10rem 0;
    }

    h2 {
        font-size: 1.7rem;
        text-transform: uppercase;
        font-weight: normal;
        color: $color-orange;

        @media (min-width: $breakpoint-desktop) {
            margin-bottom: 4rem;
        }
    }
    &__items {
        margin-left: -2.5rem;
        margin-right: -2.5rem;
        display: flex;
        flex-flow: row wrap;

        @media (min-width: $breakpoint-desktop-extra) {
            display: block;
            white-space: nowrap;
        }
    }
    &__item {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 4rem;

        width: 100%;

        @media (min-width: $breakpoint-tablet) {
            padding-bottom: 6rem;
            width: 50%;
        }
        @media (min-width: $breakpoint-desktop-extra) {
            padding-bottom: 0;
            width: 60%;
            display: inline-block;
            vertical-align: top;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
    &__pic {
        padding-bottom: 3rem;

        picture {
            display: block;
            overflow: hidden;
        }
        img {
            width: 100%;
            transition: transform 0.5s ease-in-out;
        }
    }
    &__item:hover &__pic img {
        transform: scale(1.03);
    }
    &__date {
        padding-bottom: 1rem;
        font-size: 1.4rem;
        color: $color-gray;
    }
    &__title {
        white-space: normal;

        a {
            font-size: 1.4rem;
            text-transform: uppercase;
            transition: color 0.2s ease-in-out;

            @media (min-width: $breakpoint-tablet) {
                font-size: 1.7rem;
            }

            &:hover {
                color: $color-orange;
            }
        }
    }
    &__all {
        padding-top: 3rem;

        a {
            color: $color-orange;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: $color-white;
            }
        }
    }
}
