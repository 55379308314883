.l-wrap {
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}
/*----------*/
.l-header {
    margin: auto;
    max-width: 128rem;
    width: 100%;

    @media (min-width: $breakpoint-tablet) {
    }
    @media (min-width: $breakpoint-desktop) {
    }
}
/*----------*/
.l-content {
    padding-top: 3rem;
    flex: 1 auto;

    @media (min-width: $breakpoint-tablet) {
        padding-top: 5rem;
    }

    &--black {
        background-color: $color-black;
        color: $color-gray;

        h1, h2, h3 {
            color: $color-white;
        }
    }

    .content__wrap {
        margin: auto;
        max-width: 128rem;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;

        @media (min-width: $breakpoint-tablet) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        @media (min-width: $breakpoint-desktop) {
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
    .content__wrap-white {
        margin: 0 -300rem;
        padding: 0 300rem;
        background-color: $color-white;
        color: $color-black;
        position: relative;
        z-index: 1;
    }
    .content__wrap-gray {
        margin: 0 -300rem;
        padding: 0 300rem;
        background-color: $color-white-10p;
        position: relative;
        z-index: 1;
    }
}
/*----------*/
.l-footer {
    background-color: $color-black;
}
/*----------*/
