.header-menu {
    display: none;
    
    @media (min-width: $breakpoint-desktop) {
        flex-flow: row wrap;
        display: flex;
    }

    &__item {
        position: relative;
        margin-left: 4rem;

        &:first-child {
            margin-left: 0;
        }

        a {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $color-black;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: $color-orange!important;
            }

            ins {
                margin-left: 1rem;
                width: 1rem;
                height: 1rem;
                background: url('../img/chevron-down-black.svg') 50% 50% no-repeat;
                transition: transform 0.2s ease-in-out;
            }
        }
    }
    &__dropdown {
        padding-top: 2rem;
        position: absolute;
        top: calc(100% + 1rem);
        left: 0;
        width: 25rem;
        opacity: 0;
        pointer-events: none;
        transition-property: top, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        li {
            padding-left: 3rem;
            padding-bottom: 0.5rem;
            position: relative;

            &::before {
                content: "—";
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 100;
                color: $color-gray;
            }
        }

        a {
            text-transform: none;
        }
    }
    &__item:hover a ins {
        transform: rotate(180deg);
    }
    &__item:hover &__dropdown {
        opacity: 1;
        top: 100%;
        pointer-events: all;
    }

    &--light &__item a {
        color: $color-gray;

        ins {
            background: url('../img/chevron-down.svg') 50% 50% no-repeat;
        }
    }
}
