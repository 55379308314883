.gun {
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin: auto;
    max-width: 92rem;
    position: relative;
    z-index: 1;

    @media (min-width: $breakpoint-tablet) {
        padding-bottom: 7rem;
    }
    @media (min-width: $breakpoint-desktop) {
        padding-bottom: 10rem;
    }

    &__text {
        .title-content {
            &__title,
            &__content {
                transform: scaleY(0);
            }
        }
    }

    &__text:first-child {
        h2 {
            @media (min-width: $breakpoint-desktop) {
                font-size: 4.6rem;
            }
        }
    }
    &__gun {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 10rem;
        @media (min-width: $breakpoint-desktop) {
            margin-bottom: 15rem;
        }
    }
    &__pic {
        margin-top: -15%;
        position: relative;
        width: 100%;

        @media (min-width: $breakpoint-mobile-extra) {
            margin-top: -10%;
            width: 25rem;
        }
        @media (min-width: $breakpoint-tablet) {
            margin-top: -10%;
            width: 30rem;
        }

        &-laser {
            padding-top: 50%;
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(0%);
            width: 500rem;
            z-index: -1;

            @media (min-width: $breakpoint-mobile-extra) {
                padding-top: 66%;
                transform: translateX(3%);
            }
            @media (min-width: $breakpoint-tablet) {
                padding-top: 71%;
                transform: translateX(4%);
            }
            @media (min-width: $breakpoint-desktop) {
                padding-top: 95%;
                transform: translateX(4%);
            }
            &::before {
                content: '';
                width: 100%;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom: 1px solid $color-red;
                animation: laser 2s ease-in-out 0.5s forwards infinite;
            }

            @keyframes laser {
                0% {
                    left: 0;
                    width: 0;
                }
                70% {
                    left: 100%;
                    width: 100%;
                }
                100% {
                    left: 100%;
                    width: 100%;
                }
            }
        }

        img {
            margin-left: -140%;
            pointer-events: none;
            max-width: 300%;

            @media (min-width: $breakpoint-mobile-extra) {
                max-width: 400%;
            }

            @media (min-width: $breakpoint-tablet) {
                margin-left: 0;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                max-width: 422%;
            }
            @media (min-width: $breakpoint-desktop) {
                max-width: 572%;
            }
            
        }
    }
    &__content {
        width: 100%;
        transform: scaleY(0);

        @media (min-width: $breakpoint-mobile-extra) {
            padding-top: 30%;
            width: calc(100% - 25rem);
        }
        @media (min-width: $breakpoint-tablet) {
            padding-top: 30%;
            width: calc(100% - 30rem);
        }

        p {
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__video {
        display: none;
        padding-top: 3rem;

        a {
            font-size: 1.7rem;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            color: $color-orange;
        }
        img {
            margin-right: 1.5rem;
            background-color: $color-orange;
            transition: background-color 0.2s ease-in-out;
        }

        &:hover {
            img {
                background-color: $color-gray;
            }
        }
    }
}
