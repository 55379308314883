.work {
    padding-top: 7rem;
    padding-bottom: 7rem;

    @media (min-width: $breakpoint-mobile-extra) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .title-content__title,
    .title-content__content {
        transform: scaleX(0);
        transform-origin: 100% 100%;
    }

    &__text {
        margin-bottom: 7rem;
    }
    &__carousel {}
}
//
.swiper-work {
    margin: 0;
    overflow: visible;
    max-width: 90%;
        
    @media (min-width: $breakpoint-tablet) {
        margin: auto;
        max-width: 92rem;
    }

    .swiper-pagination {
        padding-top: 5rem;
        position: static;
        text-align: left;
    }
    .swiper-pagination-bullet {
        border-radius: 0;
        width: 1.4rem;
        height: 1.4rem;
        background-color: $color-white-30p;
        opacity: 1;
        transition: background 0.2s ease-in-out;

        &-active {
            background-color: $color-orange;
        }
    }
    .swiper-slide {
        img {
            width: 100%;
        }
    }
}
