.cost-benefits {
    margin: auto;
    max-width: 92rem;
    margin-bottom: 2rem;
    padding-top: 7rem;
    font-size: 1.4rem;

    @media (min-width: $breakpoint-mobile-extra) {
        margin-bottom: 6rem;
        padding-top: 10rem;
    }
    @media (min-width: $breakpoint-tablet) {
        font-size: inherit;
    }

    &__text {
        margin-bottom: 5rem;
        max-width: 42rem;

        .title-content.title-content--block {
            transform: scaleX(0);
        }

        @media (min-width: $breakpoint-tablet) {
            margin-bottom: 7rem;
        }
        @media (min-width: $breakpoint-desktop) {
            margin-bottom: 10rem;
        }
    }

    &__items {
        margin-left: -2.5rem;
        margin-right: -2.5rem;
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        padding: 0 2.5rem 4rem;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        @media (min-width: $breakpoint-tablet) {
            padding-bottom: 3.5rem;
            width: 50%;
        }

        img {
            transform: scaleY(0);
            transform-origin: 0% 100%;
        }

        &-content {
            padding-top: 3rem;
            width: 100%;
            color: $color-gray;
            transform: scaleY(0);
            transform-origin: 0% 100%;

            @media (min-width: $breakpoint-tablet) {
                padding-top: 0;
                width: calc(100% - 6.4rem - 3rem);
            }
        }
    }
}
