/*--------------------*/
.scenario {
    padding-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 92rem;

    @media (min-width: $breakpoint-mobile-extra) {
        padding-bottom: 10rem;
    }

    &__inner {
        margin-left: -4rem;
        margin-right: -4rem;
        display: flex;
        flex-flow: row wrap;
    }
    &__block {
        padding-left: 4rem;
        padding-right: 4rem;
        width: 100%;
        @media (min-width: $breakpoint-tablet-extra) {
            width: 50%;
        }
    }

    &__nav {
        margin-bottom: 3rem;
        width: 100%;
        position: relative;
        z-index: 1;
    

        @media (min-width: $breakpoint-mobile-extra) {
            margin-bottom: 1rem;
            overflow: auto;
            white-space: nowrap;
        }

        @media (min-width: $breakpoint-tablet-extra) {
            display: block;
            white-space: inherit;
            overflow: visible;
        }
    }
    &__nav-title {
        padding-bottom: 2rem;
        width: 100%;
        text-transform: uppercase;
        color: $color-orange;

        @media (min-width: $breakpoint-mobile-extra) {
            padding-bottom: 4rem;
        }
    }
    &__nav-item {
        margin-bottom: 2rem;
        margin-right: 3rem;
        cursor: pointer;
        font-size: 2rem;
        color: $color-gray;
        transition-property: color;
        transition-duration: 0.2s;
        display: block;
        transition-timing-function: ease-in-out;
        
        &:last-child {
            margin-right: 0;
        }
        @media (min-width: $breakpoint-mobile-extra) {
            display: inline-block;
        }
        @media (min-width: $breakpoint-tablet-extra) {
            margin-right: 0;
            display: block;
            font-size: 3.2rem;
            color: $color-black;
        }

        span {
            border-bottom: 3px solid transparent;
        }

        &:hover {
            color: $color-black;

            span {
                border-bottom-color: $color-orange;
            }
        }
        &.active {
            color: $color-black;

            span {
                border-bottom-color: $color-orange;
            }
        }
    }
    &__title {
        display: block;
        position: relative;

        @media (min-width: $breakpoint-desktop) {
            display: none;
        }

        span {
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-block;
            position: relative;
            font-weight: bold;
            color: $color-font;
        }

        &::before {
            content: '';
            margin-top: -0.4rem;
            display: block;
            width: 1.4rem;
            height: 0.8rem;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: rotate(180deg);
            transition-property: background, transform;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
        }
        &.active {
            span {
                color: $color-font;
            }

            &::before {
                content: '';
                margin-top: -0.4rem;
                display: block;
                width: 1.4rem;
                height: 0.8rem;
                position: absolute;
                right: 1rem;
                top: 50%;
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893Z' fill='%2300b6da'/%3E%3C/svg%3E%0A");
                transform: rotate(0deg);
            }
        }
    }
    &__content {
        display: none;

        &:not(.show) {
            opacity: 0;
        }
        &.show {
            display: block;
        }
    }
    &__pic {
        padding-bottom: 4rem;

        img {
            width: 100%;
        }
    }
    &__items {
        width: 100%;
        position: relative;
        padding-left: 4rem;
        padding-right: 4rem;

        @media (min-width: $breakpoint-tablet-extra) {
            width: 50%;
        }
    }
    &__item {
        &:first-child {
            &__content {
                display: block;
                opacity: 1;
            }
        }

        p {
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@media (min-width: $breakpoint-desktop) {
    .scenario {
        &__title {
            display: none;
        }
    }
}
