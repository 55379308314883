.gun-benefits {
    margin: auto;
    margin-bottom: 4rem;
    max-width: 102.4rem;

    @media (min-width: $breakpoint-tablet) {
        margin-bottom: 5rem;
    }
    @media (min-width: $breakpoint-desktop) {
        margin-bottom: 8rem;
    }

    &__pic {
        padding-bottom: 4rem;
        display: block;
        transform: scaleY(0);
        
        @media (min-width: $breakpoint-mobile-extra) {
            padding-bottom: 6rem;
        }

        img {
            max-width: 100%;
        }
    }

    &__items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        transform: scaleX(0);
    }

    &__item {
        padding-top: 2rem;
        padding-bottom: 4rem;
        border-top: 1px solid $color-white-30p;

        &:first-child {
            @media (max-width: calc(#{$breakpoint-mobile-extra} - 1px)) {
                border-top: 0;
                padding-top: 0;
            }
        }

        @media (min-width: $breakpoint-mobile-extra) {
            width: calc(50% - 5rem);
            border-top: 1px solid $color-white;
        }
        @media (min-width: $breakpoint-tablet) {
            //width: calc(33.33% - 5rem);
            width: calc(50% - 3rem);
        }
    }
}