.no-scroll {
    overflow: hidden;
}
// --
.sidebar {
    position: fixed;
    overflow: auto;
    top: -50%;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-flow: column nowrap;
    background-color: $color-white;
    transform: scale(0.8);
    transition-property: top, opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    pointer-events: none;

    &.active {
        pointer-events: all;
        top: 0;
        opacity: 1;
        transform: scale(1);
    }

    // @media (min-width: $breakpoint-tablet) {
    //     max-width: 38rem;
    // }

    &__top {
        padding-top: 3rem;
        padding-bottom: 6rem;
        text-align: center;
    }
    &__close {
        padding-bottom: 5rem;
        text-align: center;

        span {
            padding: 1.2rem;
            border: 1px solid $color-gray;
            border-radius: 100rem;
            display: inline-block;
        }
    }
    &__city {
        margin-right: 3rem;

        span {
            display: inline;
            position: relative;
            margin-right: 2rem;
            border-bottom: 1px solid transparent;
            cursor: pointer;
            transition: border-color 0.2s ease-in-out;

            &:hover {
                border-bottom-color: $color-white;
            }

            &::after {
                content: '';
                margin-left: 1rem;
                position: absolute;
                left: 100%;
                top: calc(50% - 0.3rem);
                display: inline-block;
                width: 0.8rem;
                height: 0.6rem;
                background: url('../img/icon-down.svg') 50% 50% no-repeat;
            }
        }
    }
    &__header {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        border-bottom: 1px solid $color-gray;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        position: relative;
        opacity: 0;
        left: -2rem;
        pointer-events: none;
        transition-property: opacity, left;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &.active {
            pointer-events: all;
            opacity: 1;
            left: 0;
        }
    }
    &__back-btn {
        position: absolute;
        opacity: 0;
        left: 2rem;
        pointer-events: none;
        transition-property: opacity, left;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        font-size: 1.6rem;
        color: $color-gray;
        cursor: pointer;

        img {
            margin-top: -0.2em;
            padding-right: 0.5rem;
            vertical-align: middle;
        }

        &.active {
            pointer-events: all;
            opacity: 1;
            left: 0;
        }
    }
    &__inner {
        // overflow-x: hidden;
        // overflow-y: auto;
    }

    &--filter {
        .sidebar__inner {
            overflow-x: hidden;
            overflow-y: hidden;
            display: flex;
            flex-flow: column nowrap;
        }

        .sidebar__header,
        .sidebar__footer {
            margin: 0;
            padding: 1rem 2rem;
            border: 0;
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
            flex: none;
        }
        .sidebar__filter {
            overflow-x: hidden;
            overflow-y: auto;
            flex: 1 auto;
        }
        .sidebar__header {
            font-weight: 2rem;
            font-weight: 600;
        }
        .sidebar__footer {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
}
//
.sidebar-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-black;
    z-index: 998;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.active {
        opacity: 0.3;
        pointer-events: all;
    }
}
// menu
.sidebar__menu {
    margin: 0 auto 10rem;
    max-width: 30rem;
    padding-left: 4rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 auto;
    border-left: 1px solid $color-gray-40p;

    &-level-1 {
        position: relative;
        transition-property: transform, height;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &.active {
            transform: translateX(-100%);
        }

        &--blue {
            background-color: $color-black;
            color: $color-white;

            .sidebar__menu-item-1 {
                a {
                    color: $color-white;
                }
            }
        }
    }
    &-item-1 {
        padding-bottom: 3rem;

        &:last-child {
            padding-bottom: 0;
        }

        span {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
        ins {
            width: 3rem;
            height: 3rem;
            background: url('../img/chevron-down-black.svg') 50% 50% no-repeat;
            transition: transform 0.2s ease-in-out;

            &:hover {
                opacity: 0.75;
            }
        }
        &.active ins {
            transform: rotate(-180deg);
        }
        a {
            display: block;
            font-weight: 500;
            font-size: 1.7rem;
            color: $color-black;
            text-transform: uppercase;

            &:hover {
                color: $color-black;
            }
        }
    }
    &-item-spacer {
        font-weight: 600;
    }
    &-level-2 {
        padding-top: 2rem;
        width: 100%;
        display: none;

        &.active {
            display: block;
        }
    }
    &-item-spacer {
        margin-bottom: 1rem;
    }
    &-item-2 {
        a {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            text-transform: none;
            font-weight: normal;
            color: $color-gray;
        }
    }
}
