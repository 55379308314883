@import "./part/_var";
@import "./part/_reset";
@import "./part/_fonts";

@import "./part/app";
@import "./part/layout";

@import "./libs/swiper-bundle";

@import "./part/header";
@import "./part/footer";

@import "./part/sidebar";
@import "./part/header-menu";
@import "./part/footer-menu";
@import "./part/promo";
@import "./part/benefits";
@import "./part/title-content";
@import "./part/numbers";
@import "./part/work";
@import "./part/news";
@import "./part/form";
@import "./part/cost";
@import "./part/cost-benefits";
@import "./part/gun-benefits";
@import "./part/contacts";
@import "./part/inside";
@import "./part/scenario";
@import "./part/gun";
@import "./part/pagenotfound";
@import "./part/details-page";