.inside {
    padding-top: 7rem;
    max-width: 92rem;
    margin: auto;
    
    @media (min-width: $breakpoint-mobile-extra) {
        padding-bottom: 7rem;
    }
    @media (min-width: $breakpoint-desktop) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .title-content {
        transform: scaleX(0);
    }

    &__text {
        padding-bottom: 4rem;
    }
    &__pic {
        padding-bottom: 5rem;
        text-align: center;

        img {
            max-width: 100%;
            transform: scaleY(0);
        }
    }
    &__collapse {
        display: none;

        @media (min-width: $breakpoint-tablet-extra) {
            display: block;
        }

        &-title {
            padding-right: 4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-top: 1px solid $color-black;
            border-bottom: 1px solid $color-black;
            position: relative;
            cursor: pointer;
            user-select: none;
            transition-property: border-color, color;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;

            span {
                margin-top: -1.1rem;
                position: absolute;
                top: 50%;
                right: 1rem;
                width: 2.2rem;
                height: 2.2rem;
                pointer-events: none;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    border-bottom: 1px solid $color-black;
                    height: 0;
                    background-color: $color-black;
                    transform: translate(-50%, -50%);
                    opacity: 1;
                    transition-property: opacity, transform;
                    transition-duration: 0.4s;
                    transition-timing-function: ease-in-out;
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(-90deg);
                }
            }
            &:hover {
                color: $color-orange;
            }
            &.active {
                border-bottom-color: transparent;
            }
            &.active span::before {
                transform: translate(-50%, -50%) rotate(180deg);
            }
            &.active span::after {
                transform: translate(-50%, -50%) rotate(-180deg);
                opacity: 0;
            }
        }
        &-content {
            display: none;
            font-size: 1.4rem;

            strong {
                font-weight: bold;
                padding-bottom: 0.5rem;
            }

            ul {
                padding-bottom: 2rem;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}
