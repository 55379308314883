.cost {
    margin: 0 auto 8rem;
    max-width: 92rem;

    @media (min-width: $breakpoint-tablet) {
        margin-bottom: 10rem;
    }

    &__pic {
        padding-bottom: 4rem;

        img {
            max-width: 100%;
            transform: scaleX(0);
        }
    }

    &__cost {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-bottom: 3rem;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &-price {
            padding-left: 1rem;
            padding-right: 1rem;
            width: 100%;
            background: linear-gradient(224.03deg, #ff8a00 3.33%, #ffc700 101.41%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 4rem;
            color: transparent;
            transform: scaleX(0);

            @media (min-width: $breakpoint-mobile-extra) {
                width: auto;
                margin-right: 2rem;
                font-size: 6rem;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 7rem;
            }
            @media (min-width: $breakpoint-desktop) {
                font-size: 10rem;
            }
        }

        &-text {
            padding-left: 1rem;
            padding-right: 1rem;
            width: 100%;
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 300;
            color: $color-white-70p;
            transform: scaleX(0);

            @media (min-width: $breakpoint-mobile-extra) {
                width: 50%;
            }
            @media (min-width: $breakpoint-desktop) {
                font-size: 2.4rem;
            }
        }
    }

    &__info {
        margin-left: -5rem;
        margin-right: -5rem;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        
        &-buy {
            padding-bottom: 3rem;
            padding-left: 5rem;
            padding-right: 5rem;
            font-size: 1.4rem;
            line-height: 1.8;
            color: $color-gray;
            
            @media (min-width: $breakpoint-mobile-extra) {
                padding-bottom: 0;
                border-right: 1px solid $color-gray-40p;
                width: 36rem;
            }
            @media (min-width: $breakpoint-desktop) {
                border-right: 0;
            }

            a {
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-bottom: 2.5rem;
                font-weight: bold;
                letter-spacing: 0.025em;
                color: $color-white;
            }
        }
        &-content {
            padding-left: 5rem;
            padding-right: 5rem;
            width: 100%;
            
            @media (min-width: $breakpoint-mobile-extra) {
                width: calc(100% - 36rem);
            }
            @media (min-width: $breakpoint-desktop) {
                border-left: 1px solid $color-gray-40p;
            }
        }
    }
}
