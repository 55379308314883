.benefits {
    color: $color-black;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (min-width: $breakpoint-mobile-extra) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    h2 {
        color: $color-black;
    }
    a {
        color: $color-black;
    }

    &__inner {
        margin: auto;
        max-width: 92rem;
    }
    &__items {
        margin-left: -0.6rem;
        margin-right: -0.6rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    &__item {
        overflow: hidden;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        padding-bottom: 1.2rem;
        position: relative;
        width: 100%;

        @media (min-width: $breakpoint-mobile-extra) {
            width: 50%;
        }
        @media (min-width: $breakpoint-tablet-extra) {
            max-width: 27rem;
            width: 33.33%;
        }

        picture {
            position: relative;
            overflow: hidden;
            display: block;

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 70%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.77%, #000000 100%);
                z-index: 1;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(255, 138, 0, 0.5), rgba(255, 138, 0, 0.5));
                z-index: 1;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            img {
                height: 100%;
                width: 100%;
                transition: transform 0.5s ease-in-out;
                object-fit: cover;
            }
        }

        &:hover {
            picture {
                &::before {
                    opacity: 0;
                }
                &::after {
                    opacity: 1;
                }
            }
            img {
                transform: scale(1.03);
            }
        }

        &--title {
            padding-bottom: 5rem;
            margin-right: auto;
            width: 100%;

            @media (min-width: $breakpoint-tablet-extra) {
                padding-bottom: 1.2rem;
                width: 33.33%;
            }

            &:hover img {
                transform: none;
            }
        }
        &--big {
            width: 100%;

            @media (min-width: $breakpoint-tablet-extra) {
                max-width: 54rem;
                width: 66.66%;
            }
        }
    }
    &__title {
        position: absolute;
        bottom: 3rem;
        left: 2.3rem;
        right: 2.3rem;
        z-index: 2;

        & > a {
            font-size: 2.4rem;
            letter-spacing: 0.025em;
            color: $color-white;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: -200rem;
                right: -200rem;
                bottom: -200rem;
                left: -200rem;
            }
        }
    }
    &__video {
        display: none;
        a {
            font-size: 1.7rem;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            color: $color-orange;
        }
        img {
            margin-right: 1.5rem;
            background-color: $color-orange;
            transition: background-color 0.2s ease-in-out;
        }

        &:hover {
            img {
                background-color: $color-gray;
            }
        }
    }
}
