.form {
    padding-bottom: 7rem;
    padding-top: 7rem;

    @media (min-width: $breakpoint-mobile-extra) {
        padding-bottom: 10rem;
        padding-top: 10rem;
    }

    ::-webkit-input-placeholder {
        color: $color-black-20p;
        text-transform: uppercase;
    }
    :-moz-placeholder {
        color: $color-black-20p;
        text-transform: uppercase;
        opacity: 1;
    }
    ::-moz-placeholder {
        color: $color-black-20p;
        text-transform: uppercase;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: $color-black-20p;
        text-transform: uppercase;
    }
    h2 {
        color: $color-black;

        @media (min-width: $breakpoint-desktop) {
            font-size: 4.6rem;
        }
    }

    &__inner {
        margin: auto;
        max-width: 81.5rem;
        width: 100%;
    }
    &__items {
    }
    &__item {
        padding-bottom: 4rem;

        &:last-child {
            padding-bottom: 0;
        }

        input {
            padding: 1.5rem 0;
            background: none;
            border: solid #000;
            border-width: 0 0 1px;
            font-weight: bold;
            line-height: 1;
            font-size: 1.7rem;
            width: 100%;
            
            @media (min-width: $breakpoint-tablet-extra) {
                font-size: 3.2rem;
            }
        }
    }
}
