*,
*::before,
*::after {
    box-sizing: border-box;
}
::selection {
    background: $color-orange;
    color: $color-white;
}
::-moz-selection {
    background: $color-orange;
    color: $color-white;
}
html * {
    max-height: 999999px;
}
html,
body {
    height: 100%;
    position: relative;
}
html {
    font-size: 10px;
}
body {
    font-family: $font-family;
    line-height: 1.5;
    font-weight: normal;
    font-size: 1.4rem;
    color: $color-font;
    letter-spacing: 0.025em;
    -webkit-backface-visibility: hidden;
    -webkit-text-size-adjust: 100%;

    @media (min-width: $breakpoint-tablet) {
        font-size: 1.7rem;
    }
}
sup {
    vertical-align: super;
    line-height: 0;
}
h1,
.h1 {
    margin-bottom: 0.75em;
    font-family: $font-family;
    line-height: 1.15;
    font-weight: bold;
    font-size: 4rem;
    letter-spacing: 0.025em;
    color: $color-font;

    @media (min-width: $breakpoint-desktop) {
        font-size: 6.5rem;
    }
}
h2,
.h2 {
    margin-bottom: 0.75em;
    font-family: $font-family;
    line-height: 1.3;
    font-weight: bold;
    font-size: 2.4rem;
    letter-spacing: 0.025em;
    color: $color-font;

    @media (min-width: $breakpoint-desktop) {
        font-size: 3.2rem;
    }
}
h3,
.h3 {
    margin-bottom: 0.75em;
    font-family: $font-family;
    line-height: 1.3;
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.025em;
    color: $color-font;

    @media (min-width: $breakpoint-desktop) {
        font-size: 1.8rem;
    }
}
h4,
.h4 {
    margin-bottom: 0.75em;
    font-family: $font-family;
    line-height: 1.2;
    font-weight: bold;
    font-size: 1.6rem;
    color: $color-font;
}
a {
    color: $color-white;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}
a:hover {
    color: $color-white;
}
ol.ol {
    padding-left: 2rem;
    list-style: decimal outside;

    li {
        padding-bottom: 1em;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
.ov-h {
    overflow: hidden !important;
}
/*----------*/
.b-ruble-font {
    font-family: $font-family-ruble;
}
/*----------*/
.b-button,
a.b-button,
button.b-button {
    padding: 1.5rem 3rem;
    display: block;
    background: $color-orange;
    border: 0;
    opacity: 1;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    color: $color-white;
    transition-property: background, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
        background: $color-gray;
    }
    &--small {
        padding: 1.1rem;
        font-size: 1.5rem;
        width: 30rem;
        max-width: 100%;
    }
    &--border {
        border: 2px solid $color-orange;
        background-color: transparent;

        &:hover {
            background-color: $color-orange;
            color: $color-white;
        }
    }
    &--mw250 {
        margin: auto;
        max-width: 25rem;
    }
    &--center {
        margin: auto;
        max-width: 25rem;
    }
}
/*----------*/
@media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    .hide-on-mobile {
        display: none !important;
    }
}
@media (min-width: $breakpoint-tablet) and (max-width: calc(#{$breakpoint-desktop} - 1px)) {
    .hide-on-tablet {
        display: none !important;
    }
}
@media (min-width: $breakpoint-desktop) {
    .hide-on-desktop {
        display: none !important;
    }
}

.scale-in-ver-bottom {
    -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
    animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

.translate-in-hor-left {

    animation: mobile-translate-in-hor-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;

    @media (min-width: $breakpoint-desktop) {
        -webkit-animation: translate-in-hor-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
        animation: translate-in-hor-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
    }
}

.scale-in-ver-center {
    -webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
    animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

.scale-in-hor-left {
    -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
    animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
}

.scale-in-hor-right {
    -webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
    animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
}
/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}
@keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes translate-in-hor-left {
    0% {
        transform: translateX(-150%);
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        transform: translateX(-50%);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes mobile-translate-in-hor-left {
    0% {
        transform: translateX(-150%);
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        transform: translateX(0%);
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes scale-in-hor-left {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
    }
}
@keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
    }
}
/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-left {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes scale-in-hor-left {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
/**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-right {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@keyframes scale-in-hor-right {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}